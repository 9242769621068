<template>
  <div class="d-flex align-center">
    <v-text-field label="Название" v-model="name" @keyup.enter="submit" @keyup.esc="$emit('hide')" :autofocus="autofocus" outlined dense hide-details />
    <v-checkbox v-model="isDigit" label="Число" color="primary" class="ml-2" />
    <v-checkbox v-model="isMultiValued" label="Множественное" color="primary" class="ml-2" />
    <v-btn icon color="success" :disabled="name.length === 0" @click="submit" @keyup.esc="$emit('hide')">
      <v-icon>
        mdi-check
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CreateOrUpdateAttribute',
  props: {
    attributeGroup: { type: Object, default: null },
    attribute: { type: Object, default: null },
    autofocus: { type: Boolean, default: false },
  },
  data() {
    return {
      name: '',
      id: null,
      isDigit: false,
      isMultiValued: false,
      isLoading: false,
    };
  },
  watch: {
    attribute: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.id = value.id;
          this.name = value.name;
          this.isDigit = value.is_digit;
          this.isMultiValued = value.is_multivalued;
        }
      },
    },
  },
  methods: {
    async submit() {
      this.isLoading = true;

      try {
        if (this.id) {
          await this.UPDATE_ATTRIBUTE({
            attributeId: this.id,
            params: {
              name: this.name,
              is_digit: this.isDigit,
              is_multivalued: this.isMultiValued,
            },
          });
          this.$notify.showMessage({ message: `Параметр «${this.name}» обновлён`, color: 'success' });
        } else {
          const { id } = await this.CREATE_ATTRIBUTE({
            name: this.name,
            is_digit: this.isDigit,
            is_multivalued: this.isMultiValued,
          });
          this.$notify.showMessage({ message: `Параметр «${this.name}» создан`, color: 'success' });
          if (this.attributeGroup) {
            this.ADD_ATTRIBUTE_TO_GROUP({
              attributeGroupId: this.attributeGroup.id,
              attributeId: id,
            });
          }
        }
        this.$emit('hide');
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    ...mapActions('attributes', ['CREATE_ATTRIBUTE', 'UPDATE_ATTRIBUTE']),
    ...mapActions('attribute_groups', ['ADD_ATTRIBUTE_TO_GROUP']),
  },
};
</script>
