<template>
  <v-tooltip bottom open-delay="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="error" v-bind="attrs" v-on="on" @click="submit"><v-icon>mdi-delete</v-icon></v-btn>
    </template>
    <span>Удалить параметр</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DeleteAttributeButton',
  props: {
    id: { type: Number, required: true },
  },
  methods: {
    async submit() {
      this.isLoading = true;

      try {
        await this.DELETE_ATTRIBUTE(this.id);
        this.$notify.showMessage({ message: 'Атрибут удалён', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    ...mapActions('attributes', ['DELETE_ATTRIBUTE']),
  },
};
</script>
