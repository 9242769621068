<template>
  <div>
    <v-col cols="12">
      <v-row align="baseline">
        <h1>Параметры инфомоделей <span class="subtitle-1">{{ attributesCount }} </span></h1>
      </v-row>
    </v-col>
    <v-col cols="12">
      <AttributesFilter />
    </v-col>
    <v-col class="my-15 text-center" cols="12" v-if="isLoading">
      <v-progress-circular size="32" indeterminate />
    </v-col>
    <v-col cols="12" v-else>
      <InfomodeAttributesTable />
    </v-col>
    <v-row justify="center" v-if="pageCount > 1">
      <v-col cols="3">
        <v-pagination v-model="page" :length="pageCount" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import InfomodeAttributesTable from '@/components/InfomodeAttributesTable/InfomodeAttributesTable';
import AttributesFilter from '@/components/InfomodeAttributesTable/AttributesFilter';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  name: 'Infomodels',
  components: {
    InfomodeAttributesTable,
    AttributesFilter,
  },
  mixins: [PaginationMixin],
  data() {
    return {
      itemsPerPage: 24,
      isLoading: false,
      AddInfomodelFormIsShown: false,
    };
  },
  metaInfo() {
    return {
      title: 'Параметры инфомоделей — Инвентарь',
    };
  },
  computed: {
    ...mapState('attributes', ['attributesCount']),
    pageCount() {
      return Math.ceil(this.attributesCount / this.itemsPerPage);
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler: debounce(async function (params) {
        this.isLoading = true;

        try {
          await this.FETCH_ATTRIBUTES({ ...params, page_size: this.itemsPerPage });
        } catch (e) {
          if (!e.isAxiosError) throw e;
          this.$notify.showErrorMessage(e.response);
        } finally {
          this.isLoading = false;
        }
      }, 400),
    },
  },
  methods: {
    ...mapActions('attributes', ['FETCH_ATTRIBUTES']),
  },
};
</script>
