<template>
  <v-simple-table class="infomodel-table" v-if="attributesCount">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Название</th>
          <th class="text-left">Действия</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="attribute in attributes" :key="attribute.id">
          <td v-if="editId === attribute.id" style="max-width: 100px">
            <CreateOrUpdateAttribute :attribute="attribute" @hide="editId = null" />
          </td>
          <td v-else>
            {{ attribute.name }}
          </td>
          <td>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" :disabled="!attribute" v-bind="attrs" v-on="on" @click="editId = attribute.id"><v-icon>mdi-pencil</v-icon></v-btn>
              </template>
              <span>Редактировать параметр</span>
            </v-tooltip>
            <DeleteAttributeButton :id="attribute.id" />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <div v-else>Кажется тут пусто ;(</div>
</template>

<script>
import { mapState } from 'vuex';
import DeleteAttributeButton from '@/components/InfomodeAttributesTable/DeleteAttributeButton';

import CreateOrUpdateAttribute from '@/components/Infomodel/CreateOrUpdateAttribute';

export default {
  name: 'InfomodeAttributesTable',
  components: {
    DeleteAttributeButton,
    CreateOrUpdateAttribute,
  },
  data() {
    return {
      editId: null,
    };
  },
  computed: {
    ...mapState('attributes', ['attributes', 'attributesCount']),
  },
  methods: {
    getLink({ id }) {
      return `/infomodels/${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.infomodel-table {
  width: 100%;
}
</style>
